import swiper from 'swiper';

export class Carousel {
  swiper: swiper;

  constructor(selector: string) {
    const carousel: HTMLElement = document.querySelector(selector);

    if (carousel) {
      if (selector === '.features__swiper') {
        this.initSwiper(carousel);
      }

      if (selector === '.solution__swiper') {
        // After load
        if (!window.matchMedia('(min-width: 960px)').matches) {
          this.initSwiper(carousel);
        }

        window.addEventListener('resize', () => {
          if (window.matchMedia('(min-width: 960px)').matches) {
            if (this.swiper) {
              this.swiper.destroy(true, true);
              this.swiper = null;
            }
          } else {
            if (!this.swiper) {
              this.initSwiper(carousel);
            }
          }
        });
      }
    }
  }

  private initSwiper(element: HTMLElement): void {
    this.swiper = new swiper(element, {
      freeMode: true,
      slidesPerView: 'auto',
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
  }
}
