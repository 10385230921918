export class Navbar {
  navbar: HTMLElement;

  constructor(selector: string) {
    const navbar: HTMLElement = document.querySelector(selector);

    if (navbar) {
      this.navbar = navbar;

      window.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  addClass(className: string): void {
    this.navbar.classList.add(className);
  }

  removeClass(className: string): void {
    this.navbar.classList.remove(className);
  }

  private onScroll() {
    if (window.pageYOffset > 200) {
      this.addClass('active');
    } else {
      this.removeClass('active');
    }
  }
}
