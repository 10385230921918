import { tranEv } from './common';

export class MobileMenu {
  mobileMenu: HTMLElement;
  hamburgers: NodeListOf<HTMLElement>;
  closeButton: HTMLElement;
  submenus: NodeListOf<HTMLElement>;
  links: NodeListOf<HTMLAnchorElement>;

  constructor(selector: string) {
    const mobileMenu: HTMLElement = document.querySelector(selector);

    if (mobileMenu) {
      this.mobileMenu = mobileMenu;
      this.hamburgers = document.querySelectorAll('.navbar__hamburger');
      this.closeButton = mobileMenu.querySelector('.mobile-menu__close');
      this.submenus = mobileMenu.querySelectorAll('.menu-item-has-children');
      this.links = mobileMenu.querySelectorAll('.mobile-menu__menu a');

      this.setupButtons();
    }
  }

  private setupButtons(): void {
    this.hamburgers.forEach((burger: HTMLElement) => {
      burger.addEventListener('click', () => {
        this.openMenu();
      });
    });

    this.closeButton.addEventListener('click', () => {
      this.closeMenu();
    });

    this.submenus.forEach((submenu: HTMLElement) => {
      const trigger: HTMLElement = submenu.querySelector('a');
      trigger.addEventListener('click', () => {
        this.toggleSubmenu(submenu);
      });
    });

    this.links.forEach((link: HTMLAnchorElement) => {
      if (link.hash) {
        link.addEventListener('click', () => {
          this.closeMenu();
        });
      }
    });
  }

  openMenu(): void {
    this.mobileMenu.classList.add('active');
    document.body.classList.add('fixed');
  }

  closeMenu(): void {
    this.mobileMenu.classList.remove('active');
    document.body.classList.remove('fixed');
  }

  private toggleSubmenu(submenu: HTMLElement): void {
    const innerSub: HTMLElement = submenu.querySelector('.sub-menu');
    innerSub.style.height = 'auto';
    const innerSubHeight = getComputedStyle(innerSub).height;
    innerSub.style.height = '0';
    if (!submenu.classList.contains('active')) {
      // opening
      innerSub.style.height = '0';
      requestAnimationFrame(() => {
        innerSub.style.height = innerSubHeight;
      });
    } else {
      // closing
      innerSub.style.height = innerSubHeight;
      requestAnimationFrame(() => {
        innerSub.style.height = '0';
      });
    }
    innerSub.addEventListener(
      tranEv,
      () => {
        innerSub.style.height = null;
      },
      { once: true }
    );
    submenu.classList.toggle('active');
  }
}
