export class Progress {
  progressIndicator: HTMLElement;
  fixedNavbar: HTMLElement;
  hiddenNavbar: HTMLElement;

  constructor(selector: string) {
    const progressIndicator: HTMLElement = document.querySelector(selector);

    if (progressIndicator) {
      this.progressIndicator = progressIndicator;

      this.fixedNavbar = document.querySelector('.navbar:not(.navbar--hidden)');
      this.hiddenNavbar = document.querySelector('.navbar.navbar--hidden');

      if (this.hiddenNavbar) {
        window.addEventListener('scroll', this.onScroll.bind(this));
      } else {
        this.progressIndicator.classList.add('push');
      }
    }
  }

  recalculate(): void {
    const pageHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const progress = window.pageYOffset / pageHeight;
    this.progressIndicator.style.transform = `scaleY(${progress})`;
  }

  addClass(className: string): void {
    this.progressIndicator.classList.add(className);
  }

  removeClass(className: string): void {
    this.progressIndicator.classList.remove(className);
  }

  private onScroll() {
    if (this.hiddenNavbar.classList.contains('active')) {
      this.progressIndicator.classList.add('push');
    } else {
      this.progressIndicator.classList.remove('push');
    }
  }
}
