import { animEv } from './common';

export class Tabs {
  tabsSwitches: HTMLElement;
  tabsContent: NodeListOf<HTMLElement>;
  selectedTab: Number = 0;
  switchClass: string;

  constructor(selector: string) {
    const tabs: HTMLElement = document.querySelector(selector);
    let prefix: string;

    if (tabs) {
      if (selector === '.how__tabs') {
        prefix = 'how';
      } else if (selector === '.opportunities__tabs') {
        prefix = 'opportunities';
      }

      this.tabsSwitches = tabs.querySelector(`.${prefix}__tabs__switches`);
      this.tabsContent = tabs.querySelectorAll(`.${prefix}__tabs__content`);
      this.switchClass = `.${prefix}__switch`;

      this.setupTabs();
    }
  }

  private setupTabs(): void {
    this.tabsSwitches.addEventListener('click', (e: Event) => {
      const switchButton: Element = (<HTMLElement>e.target).closest(
        this.switchClass
      );
      if (switchButton) {
        this.selectedTab = parseInt(switchButton.getAttribute('data-tab'), 10);
        this.selectTab(this.selectedTab, switchButton);
      }
    });
  }

  private selectTab(id: Number, button: Element): void {
    this.tabsSwitches.querySelector('.active').classList.remove('active');
    button.classList.add('active');

    this.tabsContent.forEach((tabs: HTMLElement) => {
      const activeTab = tabs.querySelector('.active');
      const nextTab = tabs.querySelector(`[data-tab='${id}']`);
      activeTab.classList.add('fade-out');
      tabs.addEventListener(
        animEv,
        () => {
          activeTab.classList.remove('active');
          activeTab.classList.remove('fade-out');
          nextTab.classList.add('fade-in');
          nextTab.classList.add('active');
          tabs.addEventListener(
            animEv,
            () => {
              nextTab.classList.remove('fade-in');
            },
            { once: true }
          );
        },
        { once: true }
      );
    });
  }
}
