export class ScrollDown {
  constructor(selector: string, toSelector: string) {
    const trigger: HTMLElement = document.querySelector(selector);
    const to: HTMLElement = document.querySelector(toSelector);

    if (trigger && to) {
      trigger.addEventListener('click', () => {
        to.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      });
    }
  }
}
