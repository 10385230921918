import '../scss/style.scss';
import 'element-closest';
import { Navbar } from './components/navbar';
import { MobileMenu } from './components/mobileMenu';
import { Tabs } from './components/tabs';
import { Products } from './components/products';
import { Carousel } from './components/carousel';
import { Modal } from './components/modal';
import { Progress } from './components/progress';
import { ScrollDown } from './components/scrollDown';
import { LoadActive } from './components/LoadActive';
import { Reveal } from './components/reveal';
import { Scrollspy } from './components/scrollspy';
import { Cookies } from './components/cookies';

(function() {
  if (typeof (NodeList as any).prototype.forEach !== 'function') {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }

  return false;
})();
let mobileMenu: MobileMenu;
let progress: Progress;

const onLoad = (e: Event) => {
  inits();
  onResize(e);
};

const onResize = (e: Event) => {
  onScroll(e);
  if (window.innerWidth > 960) {
    mobileMenu.closeMenu();
  }
};

const onScroll = (e: Event) => {
  if (progress) {
    progress.recalculate();
  }
};

const inits = () => {
  new Navbar('.navbar.navbar--hidden');
  mobileMenu = new MobileMenu('.mobile-menu');
  new Tabs('.how__tabs');
  new Tabs('.opportunities__tabs');
  new Carousel('.features__swiper');
  new Carousel('.solution__swiper');
  new Modal('.offer-modal');
  progress = new Progress('.progress');
  new ScrollDown('.hero__scroll', '.how');
  new ScrollDown('.hero__arrow', '.how');
  new LoadActive('.hero');
  new LoadActive('.cookies');
  new Reveal();
  new Scrollspy(
    ['#the-product', '#features', '#footer'],
    '.navbar .navbar__menu'
  );
  new Cookies('.cookies');
  // new Scrollspy('#the-product', '.navbar .navbar__menu');
  // new Scrollspy('#features', '.navbar .navbar__menu');
  // new Scrollspy('#footer', '.navbar .navbar__menu');
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);
