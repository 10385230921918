// @ts-ignore
import scrollreveal from 'scrollreveal';

export class Reveal {
  constructor() {
    scrollreveal({
      duration: 200,
      distance: '20px',
      viewOffset: {
        bottom: 100
      },
      easing: 'cubic-bezier(0.4, 0, 1, 1)'
    });

    this.setupReveal();
  }

  private setupReveal(): void {
    scrollreveal().reveal('.how .how__intro .container > *', {
      interval: 150
    });
    scrollreveal().reveal('.how .how__tabs', {
      duration: 0,
      afterReveal: this.addActive
    });
    scrollreveal().reveal('.how .how__tabs__content');
    scrollreveal().reveal('.how .how__switch', {
      interval: 150
    });
    scrollreveal().reveal('.how .how__switch__illustration', {
      distance: '40px',
      origin: 'top'
    });

    scrollreveal().reveal('.product .product__intro .container > *', {
      interval: 150
    });
    scrollreveal().reveal('.product .product__description', {
      duration: 0,
      afterReveal: this.addActive
    });

    scrollreveal().reveal('.features .features__intro .container > *', {
      interval: 150
    });
    scrollreveal().reveal('.features .features__swiper');

    scrollreveal().reveal('.links .links__link', {
      interval: 150
    });

    scrollreveal().reveal('.partners', {
      duration: 0,
      afterReveal: this.addActive
    });
    scrollreveal().reveal('.partners .partners__intro');
    scrollreveal().reveal('.partners .partners__logo', {
      interval: 150
    });

    scrollreveal().reveal('.footer', {
      duration: 0,
      afterReveal: this.addActive
    });

    scrollreveal().reveal('.opportunities', {
      duration: 0,
      afterReveal: this.addActive
    });
    scrollreveal().reveal('.solution .solution__intro .container > *', {
      interval: 150
    });
    scrollreveal().reveal('.solution .solution__swiper');
  }

  private addActive(el: HTMLElement): void {
    el.classList.add('active');
  }
}
