export class Scrollspy {
  sections: any = [];

  constructor(selectors: string[], menuSelector: string) {
    const menuLinks: NodeListOf<HTMLElement> = document.querySelectorAll(
      `${menuSelector} a`
    );

    selectors.forEach((selector: string) => {
      const sectionEl: HTMLElement = document.querySelector(selector);

      const menuLink = Array.prototype.slice
        .call(menuLinks)
        .filter((link: HTMLAnchorElement) => {
          return link.hash === `#${sectionEl.id}`;
        })[0];

      this.sections.push({
        sectionEl,
        menuLink
      });
    });

    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  private onScroll() {
    const windowScrolled = window.pageYOffset;
    const windowHeight = document.documentElement.clientHeight;

    this.sections.forEach((section: any) => {
      const sectionTop = section.sectionEl.offsetTop;
      const sectionHeight = section.sectionEl.getBoundingClientRect().height;

      if (
        windowScrolled + windowHeight - 100 > sectionTop &&
        windowScrolled + 100 < sectionTop + sectionHeight
      ) {
        section.menuLink.classList.add('active');
      } else {
        section.menuLink.classList.remove('active');
      }
    });
  }
}
