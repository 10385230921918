import * as Cookie from 'js-cookie';

export class Cookies {
  cookiesDialog: HTMLElement;

  constructor(selector: string) {
    const cookiesDialog: HTMLElement = document.querySelector(selector);

    if (cookiesDialog && Cookie.get('cookie-consent') !== 'true') {
      this.cookiesDialog = cookiesDialog;
      this.setup();
    } else {
      cookiesDialog.classList.remove('cookies--open');
    }
  }

  private setup(): void {
    const close = this.cookiesDialog.querySelector('.cookies__close');
    close.addEventListener('click', () => {
      this.cookiesDialog.classList.remove('cookies--open');
      Cookie.set('cookie-consent', 'true');
    });
  }
}
