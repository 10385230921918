export class Modal {
  openButtons: NodeListOf<HTMLElement>;
  closeButton: HTMLElement;
  modal: HTMLElement;

  constructor(selector: string) {
    const modal: HTMLElement = document.querySelector(selector);

    if (modal) {
      this.modal = modal;

      if (selector === '.offer-modal') {
        this.openButtons = document.querySelectorAll('.button--offer');
        this.closeButton = modal.querySelector('.offer-modal__close');
      }

      this.setup();
    }
  }

  private setup(): void {
    this.openButtons.forEach((button: HTMLElement) => {
      button.addEventListener('click', () => {
        this.openMenu();
      });
    });

    this.closeButton.addEventListener('click', () => {
      this.closeMenu();
    });

    this.modal.addEventListener('wpcf7mailsent', () => {
      this.closeMenu();
    });
  }

  openMenu(): void {
    this.modal.classList.add('active');
    document.body.classList.add('modal-open');
  }

  closeMenu(): void {
    this.modal.classList.remove('active');
    document.body.classList.remove('modal-open');
  }
}
